import React, { useEffect, Suspense, lazy, useState } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider, defaultTheme } from "@agilekit/ui";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ConfirmationModal from "./components/ConfirmationModal/ConfirmationModal";

// COMPONENTS
import { PageLoader, Toaster, ErrorBoundary } from "./components";

// HELPERS
import { getFacilitySettings } from "./store/modules/enviroment/enviroment.selector";
import { clearState } from "./utils/sessionStorage";
import { setAppResponsiveZoom } from "./App.helpers";
import { checkForNewVersion } from "./utils/checkForNewVersion";

const AuthenticatedApp = lazy(
	() => import("./containers/core/AuthenticatedApp/AuthenticatedApp")
);
const UnauthenticatedApp = lazy(
	() => import("./containers/core/UnauthenticatedApp/UnauthenticatedApp")
);

const App: React.FC = () => {
	const isLogged = useSelector((state: any) => state.auth.isLogged);
	const { language } = useSelector(getFacilitySettings);
	const { i18n } = useTranslation();
	const [showConfirmation, setShowConfirmation] = useState(false);
	const location = useLocation();

	const beforeUnload = () => {
		clearState("customersFilter");
	};

	useEffect(() => {
		checkForNewVersion();
	}, []);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		i18n.changeLanguage(language);
	}, [language]);

	useEffect(() => {
		window.addEventListener("beforeunload", beforeUnload);
	}, []);

	useEffect(() => {
		if (!navigator.cookieEnabled) {
			openConfirmation();
		}
	}, []);

	useEffect(() => {
		setAppResponsiveZoom(location.pathname);
	}, [location]);

	useEffect(() => {
		// eslint-disable-next-line no-console
		console.log("Sending ping to classic POS");
		window.parent.postMessage("management-ping", "*");
	}, []);

	const openConfirmation = () => {
		setShowConfirmation(true);
	};

	const closeConfirmation = () => {
		setShowConfirmation(false);
	};

	const cookieSettingsModalContent = (
		<div>
			<div style={{ display: "flex" }}>
				Party Center Software requires cookies to function properly, please make
				sure they are enabled in your browser.
			</div>
			<div style={{ display: "flex", color: "gray", paddingTop: "25px" }}>
				{" "}
				If you continue to block cookies you may experience issues loading pages.
			</div>
		</div>
	);

	return (
		<ThemeProvider theme={defaultTheme}>
			<ErrorBoundary showPage={false}>
				<Toaster />
				<Suspense fallback={<PageLoader />}>
					{isLogged ? <AuthenticatedApp /> : <UnauthenticatedApp />}
					{showConfirmation && (
						<ConfirmationModal
							title="Cookie Settings"
							content={cookieSettingsModalContent}
							open={openConfirmation}
							close={closeConfirmation}
							hideCancelButton
							confirmButtonText="Close"
							confirmOnClick={closeConfirmation}
						/>
					)}
				</Suspense>
			</ErrorBoundary>
		</ThemeProvider>
	);
};

export default App;
