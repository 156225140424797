import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "@agilekit/ui/src/assets/styles/global/styles.scss";

import * as React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "./i18n";

// ASSETS
import "./custom.css";

// COMPONENTS
import App from "./App";
import TelemetryProvider from "./components/TelemetryProvider/TelemetryProvider";

// HELPERS
import store from "./store/configureStore";
// import registerServiceWorker from "./registerServiceWorker";
import "./globalFunctions";

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") as string;
// const history = createBrowserHistory({ basename: baseUrl });
// const history = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<Provider store={store}>
		<BrowserRouter basename={baseUrl}>
			<TelemetryProvider
				instrumentationKey={process.env.REACT_APP_INSIGHTS_KEY || ""}
				after={() => {}}
			>
				<App />
			</TelemetryProvider>
		</BrowserRouter>
	</Provider>
);

// registerServiceWorker();
