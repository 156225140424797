import { AxiosPromise } from "axios";
import Http from "../../../utils/restClient";

export default class PartyScheduleApi {
	public static GetScheduleSlot(payload) {
		return Http.axios().get(
			`/api/v1/schedule/schedule-slot/${payload}`
		) as AxiosPromise<any>;
	}

	public static ContinueToOrder(scheduleId, payload) {
		return Http.axios().post(
			`/api/v1/schedule/order/${scheduleId}`,
			payload
		) as AxiosPromise;
	}

	public static BackToSchedule(scheduleId) {
		return Http.axios().delete(
			`/api/v1/schedule/order/delete/${scheduleId}`
		) as AxiosPromise;
	}

	public static AddCustomerToOrder(orderId, accountId) {
		return Http.axios().post(
			`/api/v1/schedule/order/${orderId}/customer/${accountId}`,
			{
				addToOrder: true,
			}
		) as AxiosPromise;
	}

	public static RemoveCustomerFromOrder(orderId, accountId) {
		return Http.axios().post(
			`/api/v1/schedule/order/${orderId}/customer/${accountId}`,
			{}
		) as AxiosPromise;
	}

	public static GetCustomerFromDb(parentAccount) {
		return Http.axios().get(
			`/api/v1/customers/findAccounts/${parentAccount}`
		) as AxiosPromise;
	}
}
