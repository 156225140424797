import React, { useEffect, useState } from "react";
import Magnify from "mdi-material-ui/Magnify";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash-es";
import classNames from "classnames";

// COMPONENTS
import SearchResults from "../SearchResults/SearchResults";

// ACTIONS
import {
	clearOrderSearch,
	closeSearch,
	fetchLastTenOrdersVisitedStart,
	openSearch,
	orderSearchStart,
} from "../../../../store/modules/headerNav/headerNav.slice";

// SELECTORS
import {
	isSearchNavOpen,
	getSearchFetchStateLoading,
} from "../../../../store/modules/headerNav/headerNav.selector";

// ASSETS
import useStyles from "./SearchInput.styles";
import { sanitizeForSearch } from "../../../../utils";

const SearchInput = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const isSearchOpen = useSelector(isSearchNavOpen);
	const { loading: searchisLoading } = useSelector(getSearchFetchStateLoading);

	const [searchText, setSearchText] = useState("");

	const domClick = (event) => {
		if (event.target.id !== "searchNav" && isSearchOpen) {
			dispatch(closeSearch());
		}
	};

	useEffect(() => {
		if (searchisLoading === false && !isSearchOpen && searchText.length === 0) {
			dispatch(fetchLastTenOrdersVisitedStart());
		}
	}, [isSearchOpen]);

	useEffect(() => {
		if (searchisLoading === false && searchText.length > 0) {
			dispatch(orderSearchStart({ searchText }));
		}
		if (searchisLoading === false && searchText.length === 0) {
			dispatch(clearOrderSearch());
		}
	}, [searchText]);

	useEffect(() => {
		document.addEventListener("click", domClick);

		return () => {
			document.removeEventListener("click", domClick);
		};
	}, [isSearchOpen]);

	// EVENTS
	const handleInputClick = () => {
		dispatch(openSearch());
	};

	const debouncedSearch = debounce((value: string) => {
		setSearchText(value);
	}, 2000);

	const onSearchTextChange = (e: any) => {
		let { value } = e.target;
		value = sanitizeForSearch(e.target.value);
		debouncedSearch(value);
	};

	// STYLES
	const cssSearch = classNames(classes.search, {
		[classes.searchOpen]: isSearchOpen,
	});

	return (
		<div className={classes.searchContainer}>
			<div className={cssSearch}>
				<div className={classes.searchInput}>
					<span className={classes.searchIcon}>
						<Magnify fontSize="inherit" />
					</span>
					<input
						id="searchNav"
						placeholder="Search"
						onClick={handleInputClick}
						onChange={onSearchTextChange}
						autoComplete="off"
					/>
				</div>
				{isSearchOpen && (
					<div className={classes.searchPanel}>
						<SearchResults />
					</div>
				)}
			</div>
		</div>
	);
};

export default SearchInput;
