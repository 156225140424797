import React from "react";

import { Modal, Button, Box, Row, useMediaQuery, useTheme } from "@agilekit/ui";

const ConfirmationModal = ({
	title,
	content,
	open,
	close,
	hideCancelButton,
	confirmButtonText,
	confirmOnClick,
	cancelButtonText = "Cancel",
	isDanger = true,
	isLoading = false,
	disableConfirm = false,
}) => {
	const theme = useTheme();
	const screenXS = useMediaQuery(theme.breakpoints.down("xs"));

	const closeModal = () => {
		close();
	};

	return (
		<div>
			<Modal
				open={open}
				title={title}
				onClose={closeModal}
				disableBackdropClick
				size={screenXS ? "small" : "medium"}
				data-testid="modalRemoveNote"
				footer={
					<Row justify="end" type="flex">
						<Box pr={1}>
							<Button subtle onClick={closeModal} hidden={hideCancelButton}>
								{cancelButtonText}
							</Button>
						</Box>
						<Box pl={1}>
							<Button
								danger={isDanger}
								primary={!isDanger}
								onClick={confirmOnClick}
								disabled={isLoading || disableConfirm}
								loading={isLoading}
								data-testid="confirmDeleteNodeButton"
							>
								{confirmButtonText}
							</Button>
						</Box>
					</Row>
				}
			>
				<Box p={6}>{content}</Box>
			</Modal>
		</div>
	);
};

export default ConfirmationModal;
