/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from "react";
import HelpCircleOutline from "mdi-material-ui/HelpCircleOutline";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";

// COMPONENTS
import HelpResults from "./HelpResults/HelpResults";

// ACTIONS
import { openHelp, closeHelp } from "../../../../store/modules/headerNav/headerNav.slice";

// SELECTORS
import {
	getCommunicationsNotYetAcknowledgedCount,
	isAcknowledgeModalOpen,
	isHelpOpen,
	getAcknowledgeState,
} from "../../../../store/modules/headerNav/headerNav.selector";

// ASSETS
import useStyles from "./Help.styles";
import AcknowledgeModal from "./AcknowledgeModal/AcknowledgeModal";

const Help = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const helpIsOpen = useSelector(isHelpOpen);
	const acknowledgeModalIsOpen = useSelector(isAcknowledgeModalOpen);
	const { selectedCommunication } = useSelector(getAcknowledgeState);
	const communicationsNotYetAcknowledgedCount = useSelector(
		getCommunicationsNotYetAcknowledgedCount
	);

	const domClick = (event) => {
		if (event.currentTarget.activeElement.id !== "helpButton" && helpIsOpen) {
			dispatch(closeHelp());
		}
	};

	useEffect(() => {
		document.addEventListener("click", domClick);

		return () => {
			document.removeEventListener("click", domClick);
		};
	}, [helpIsOpen]);

	// EVENTS
	const handleClick = () => {
		helpIsOpen ? dispatch(closeHelp()) : dispatch(openHelp());
	};

	// STYLES
	const cssHelp = classNames(classes.help, {
		[classes.helpOpen]: helpIsOpen,
	});

	return (
		<div className={classes.helpContainer}>
			<div className={cssHelp}>
				<div className={classes.helpIcon}>
					<button
						id="helpButton"
						type="button"
						className={classes.sectionRightButton}
						onClick={() => handleClick()}
					>
						<HelpCircleOutline id="helpButtonIcon" />
					</button>
					{communicationsNotYetAcknowledgedCount > 0 && (
						<div id="helpCount" className={classes.helpCountContainer}>
							<span className={classes.helpCount}>
								{communicationsNotYetAcknowledgedCount}
							</span>
						</div>
					)}
				</div>
				{helpIsOpen && (
					<div className={classes.helpPanel}>
						<HelpResults />
					</div>
				)}
			</div>
			{acknowledgeModalIsOpen && (
				<AcknowledgeModal
					communication={selectedCommunication}
					open={acknowledgeModalIsOpen}
				/>
			)}
		</div>
	);
};

export default Help;
