import React, { useEffect, useState } from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { History } from "history";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useDispatch } from "react-redux";

// HELPERS
import { ai, getAppInsights } from "./TelemetryProvider.helpers";
import { setAppInsights } from "../../store/modules/appInsights/appInsights.slice";

interface IProps extends RouteComponentProps {
	history: History;
	after?: () => void;
	instrumentationKey: string;
	children: React.ReactNode;
}

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
const TelemetryProvider: React.FC<IProps> = ({
	history,
	instrumentationKey,
	after,
	children,
}) => {
	const [initialized, setInitialized] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		const AppInsightsInstrumentationKey = instrumentationKey; // PUT YOUR KEY HERE
		if (!initialized && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
			ai.initialize(AppInsightsInstrumentationKey, history);
			setInitialized(true);
		}
		if (after) {
			after();
			dispatch(setAppInsights(getAppInsights()));
		}
	}, []);

	return <>{children}</>;
};

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));
