import { createSlice } from "@reduxjs/toolkit";

const appInsights = createSlice({
	name: "APP_INSIGHTS",
	initialState: {},
	reducers: {
		setAppInsights: (state, { payload }) => {
			return payload;
		},
	},
});

export const { setAppInsights } = appInsights.actions;

export default appInsights.reducer;
