import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		container: {
			width: 300,
			position: "absolute",
			right: 0,
			top: 35,
			backgroundColor: theme.palette.NEUTRAL000,
			marginTop: theme.spacing(2),
			padding: theme.spacing(2, 0),
			borderRadius: 4,
			boxShadow: theme.shadows[3],
		},
		sectionContainer: {
			borderBottom: `1px solid ${theme.palette.NEUTRAL060}`,
			fontSize: theme.typography.pxToRem(16),
			paddingTop: 5,
			"& strong": {
				color: theme.palette.NEUTRAL900,
				fontWeight: 500,
			},
		},
		sectionContainerLastItem: {
			fontSize: theme.typography.pxToRem(16),
			paddingTop: 5,
			"& strong": {
				color: theme.palette.NEUTRAL900,
				fontWeight: 500,
			},
		},
		helpList: {
			padding: theme.spacing(0, 5),
			maxHeight: 400,
			overflowY: "auto",
			overflowX: "hidden",
			"&::-webkit-scrollbar-track": {
				boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
				borderRadius: 0,
				backgroundColor: theme.palette.NEUTRAL020,
			},
			"&::-webkit-scrollbar": {
				width: 4,
				backgroundColor: "#F5F5F5",
			},
			"&::-webkit-scrollbar-thumb": {
				borderRadius: 10,
				boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
				backgroundColor: theme.palette.NEUTRAL100,
			},
		},
		loadingContainer: {
			display: "flex",
			alignItems: "center",
			height: "100%",
			width: "100%",
			justifyContent: "space-around",
		},
		footer: {
			padding: theme.spacing(0, 5),
		},
	};
});

export default useStyles;
