import { takeLatest, call, put } from "redux-saga/effects";
import PartyScheduleApi from "./partySchedule.api";
import {
	getScheduleSuccess,
	getScheduleFailure,
	getScheduleRequest,
	continueRequest,
	continueFailure,
	continueSuccess,
	backToScheduleFailure,
	backToScheduleSuccess,
	backToScheduleRequest,
	addCustomerToOrderFailure,
	addCustomerToOrderStart,
	addCustomerToOrderSuccess,
	removeCustomerFromOrderFailure,
	removeCustomerFromOrderStart,
	removeCustomerFromOrderSuccess,
	fetchBookingCustomer,
	successFetchBookingCustomer,
	failedFetchBookingCustomer,
} from "./partySchedule.slice";

export function* getScheduleSlot({ payload }): any {
	try {
		const response = yield call(PartyScheduleApi.GetScheduleSlot, payload);
		yield put(getScheduleSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(getScheduleFailure({ error }));
	}
}

export function* continueToOrder({ payload }): any {
	try {
		const response = yield call(
			PartyScheduleApi.ContinueToOrder,
			payload.scheduleId,
			payload.model
		);

		yield put(continueSuccess({ data: response }));
	} catch (error: any) {
		yield put(continueFailure({ data: error.response.data }));
	}
}

export function* backToSchedule({ payload }): any {
	try {
		const response = yield call(PartyScheduleApi.BackToSchedule, payload.scheduleId);
		yield put(backToScheduleSuccess({ data: response }));
	} catch (error: any) {
		yield put(backToScheduleFailure({ data: error.response.data }));
	}
}

export function* addCustomerToOrder({ payload }): any {
	try {
		const response = yield call(
			PartyScheduleApi.AddCustomerToOrder,
			payload.orderId,
			payload.accountId
		);

		yield put(addCustomerToOrderSuccess({ data: response }));
	} catch (error: any) {
		yield put(addCustomerToOrderFailure({ data: error.response.data }));
	}
}

export function* removeCustomerFromOrder({ payload }): any {
	try {
		const response = yield call(
			PartyScheduleApi.RemoveCustomerFromOrder,
			payload.orderId,
			payload.accountId
		);

		yield put(removeCustomerFromOrderSuccess({ data: response }));
	} catch (error: any) {
		yield put(removeCustomerFromOrderFailure({ data: error.response.data }));
	}
}

export function* getCustomerFromDb({ payload }): any {
	try {
		const response = yield call(
			PartyScheduleApi.GetCustomerFromDb,
			payload.accountId
		);
		yield put(successFetchBookingCustomer({ data: response.data }));
	} catch (error: any) {
		yield put(failedFetchBookingCustomer({ error }));
	}
}

// WATCHERS
export function* watchSchedule() {
	return [
		yield takeLatest(getScheduleRequest, getScheduleSlot),
		yield takeLatest(continueRequest, continueToOrder),
		yield takeLatest(backToScheduleRequest, backToSchedule),
		yield takeLatest(addCustomerToOrderStart, addCustomerToOrder),
		yield takeLatest(removeCustomerFromOrderStart, removeCustomerFromOrder),
		yield takeLatest(fetchBookingCustomer, getCustomerFromDb),
	];
}
