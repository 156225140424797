import React, { useMemo } from "react";
import { useSelector } from "react-redux";

// COMPONENTS
import HelpItem from "../HelpItem/HelpItem";

// SELECTOR
import {
	getHelpFetchStateLoading,
	getHelpState,
} from "../../../../../store/modules/headerNav/headerNav.selector";

// ASSETS
import useStyles from "./HelpResults.styles";
import classNames from "classnames";
import { CircularProgress } from "@material-ui/core";
import { SupportCommunication } from "../../../../../store/modules/headerNav/headerNav.typings";

const HelpResults: React.FC = () => {
	const {
		releaseNote,
		urgentCommunications,
		normalCommunications,
		communicationsByKey,
		communicationsNotYetAcknowledged,
		userTargetCommunications,
	} = useSelector(getHelpState);
	const { loading } = useSelector(getHelpFetchStateLoading);
	const classes = useStyles();

	const renderLoading = (
		<div className={classes.loadingContainer}>
			<CircularProgress size={40} />
		</div>
	);

	const renderList = (
		<div className={classes.helpList}>
			{((urgentCommunications && urgentCommunications.length > 0) ||
				(normalCommunications && normalCommunications.length > 0) ||
				(userTargetCommunications && userTargetCommunications.length > 0)) && (
				<div className={classes.sectionContainer}>
					<strong>Communication</strong>
					{urgentCommunications.map((item) => (
						<HelpItem
							key={item.id}
							communication={item}
							needAcknowledgment={communicationsNotYetAcknowledged.some(
								(x) => x.id === item.id
							)}
						/>
					))}
					{normalCommunications.map((item) => (
						<HelpItem
							key={item.id}
							communication={item}
							needAcknowledgment={communicationsNotYetAcknowledged.some(
								(x) => x.id === item.id
							)}
						/>
					))}
					{userTargetCommunications.map((item) => (
						<HelpItem
							key={item.id}
							communication={item}
							needAcknowledgment={communicationsNotYetAcknowledged.some(
								(x) => x.id === item.id
							)}
						/>
					))}
				</div>
			)}
			{communicationsByKey && communicationsByKey.length > 0 && (
				<div className={classes.sectionContainer}>
					<strong>Help</strong>
					{communicationsByKey.map((item) => (
						<HelpItem key={item.id} communication={item} />
					))}
				</div>
			)}
			{releaseNote && releaseNote.id > 0 && (
				<div className={classes.sectionContainer}>
					<strong>Release Notes</strong>
					<HelpItem
						key={releaseNote.id}
						communication={releaseNote}
						needAcknowledgment={communicationsNotYetAcknowledged.some(
							(x) => x.id === releaseNote.id
						)}
					/>
				</div>
			)}
		</div>
	);

	const cssFooter = classNames([classes.footer, classes.sectionContainerLastItem]);

	return (
		<div className={classes.container}>
			{loading ? renderLoading : renderList}
			<div className={cssFooter}>
				<HelpItem
					key="policies"
					communication={{} as SupportCommunication}
					url="https://www.partycentersoftware.com/privacy-policy-and-terms-of-use"
					title="Privacy Policy & Terms of Use"
					lastItem
				/>
			</div>
		</div>
	);
};

export default HelpResults;
