/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useMediaQuery, useTheme } from "@agilekit/ui";
import { truncate } from "lodash-es";

// ACTIONS
import {
	openUserActions,
	closeUserActions,
} from "../../../../store/modules/headerNav/headerNav.slice";
import { openFacilityAliasModal } from "../../../../store/modules/enviroment/enviroment.slice";

// SELECTORS
import { isUserActionsOpen } from "../../../../store/modules/headerNav/headerNav.selector";
import {
	getFacilityAliasState,
	getCookiesInfoState,
} from "../../../../store/modules/enviroment/enviroment.selector";

// ASSETS
import useStyles from "./UserActions.styles";
import MenuUp from "mdi-material-ui/MenuUp";
import MenuDown from "mdi-material-ui/MenuDown";
import { MenuOptions, getMenuLink } from "../../../../utils/classic.helper";
import FacilityAliasModal from "./FacilityAliasModal/FacilityAliasModal";
import { userHaveRole } from "../../../../utils/roles";

const UserActions = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const cookiesInfo = useSelector(getCookiesInfoState);
	const { results, isFacilityAliasModalOpen } = useSelector(getFacilityAliasState);
	const [jsFiles, setJsFiles] = useState<Array<any>>([]);
	const theme = useTheme();
	const screenXS = useMediaQuery(theme.breakpoints.down("xs"));

	const userActionsIsOpen = useSelector(isUserActionsOpen);

	const domClick = (event) => {
		if (
			event.target.id !== "userButton" &&
			event.target.id !== "userButtonInfo" &&
			userActionsIsOpen
		) {
			dispatch(closeUserActions());
		}
	};

	useEffect(() => {
		document.addEventListener("click", domClick);

		return () => {
			document.removeEventListener("click", domClick);
		};
	}, [userActionsIsOpen]);

	useEffect(() => {
		const scriptFileNames = Array.from(document.querySelectorAll("script"))
			.filter((script) => script.src)
			.map((script) => {
				const url = new URL(script.src);
				return url.pathname.split("/").pop();
			});

		setJsFiles(scriptFileNames);
	}, []);

	// EVENTS
	const handleClick = () => {
		userActionsIsOpen ? dispatch(closeUserActions()) : dispatch(openUserActions());
	};

	const handleMenuClick = (type: MenuOptions) => {
		const classicRoute = getMenuLink(type, cookiesInfo.userId);

		if (window.parent !== undefined)
			window.parent.window.location.href = `${classicRoute}`;
		else window.location.href = `${classicRoute}`;
	};

	const handleFacilityClick = () => {
		dispatch(openFacilityAliasModal());
	};

	const renderFacilityOptions = () => {
		if (results && results.length > 1) {
			return (
				<div className={classes.main}>
					<div className={classes.mainItem}>
						<button onClick={() => handleFacilityClick()}>
							<div>
								<span>
									{!screenXS &&
										truncate(cookiesInfo.facilityName, {
											length: 23,
										})}
								</span>
							</div>
							<div className={classes.smallText}>
								<span>Facility: </span>{" "}
								<strong>{cookiesInfo.facilityId}</strong>
							</div>
						</button>
					</div>
				</div>
			);
		}
		return (
			<div className={classes.textItem}>
				<div>
					<span>{truncate(cookiesInfo.facilityName, { length: 23 })}</span>
				</div>
				<div className={classes.smallText}>
					<span>Facility: </span> <strong>4</strong>
				</div>
			</div>
		);
	};

	// STYLES
	const css = classNames(classes.userActions, {
		[classes.userActionsOpen]: userActionsIsOpen,
	});

	return (
		<div className={classes.userActionsContainer}>
			<div className={css}>
				<button
					id="userButton"
					type="button"
					className={classes.sectionRightButton}
					onClick={() => handleClick()}
				>
					<div id="userButtonInfo" className={classes.userActions}>
						<strong id="userButtonInfo">
							{screenXS
								? cookiesInfo.userFirstLastName[0]
								: truncate(cookiesInfo.userFirstLastName, {
										length: 23,
								  })}
						</strong>
						{userActionsIsOpen ? (
							<MenuUp id="userButtonInfo" />
						) : (
							<MenuDown id="userButtonInfo" />
						)}
					</div>
				</button>
				{userActionsIsOpen && (
					<div className={classes.userActionsPanel}>
						<div className={classes.userActionsListContainer}>
							<div className={classes.userActionsList}>
								<div className={classes.sectionContainer}>
									<div className={classes.textItem}>
										<div>
											<span>
												{truncate(cookiesInfo.userFirstLastName, {
													length: 23,
												})}
											</span>
										</div>
										<div className={classes.smallText}>
											<span>{cookiesInfo.userName}</span>
										</div>
									</div>
								</div>
								<div className={classes.sectionContainer}>
									{renderFacilityOptions()}
								</div>
								{userHaveRole("Users: User Manager") && (
									<div className={classes.sectionContainer}>
										<div className={classes.main}>
											<div className={classes.mainItem}>
												<button
													onClick={() =>
														handleMenuClick("UserAccount")
													}
												>
													Manage Account
												</button>
											</div>
										</div>
									</div>
								)}
								<div className={classes.lastSectionContainer}>
									<div className={classes.main}>
										<div className={classes.mainItem}>
											<button
												onClick={() => handleMenuClick("Logout")}
											>
												Logout
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{isFacilityAliasModalOpen && <FacilityAliasModal />}
			</div>
		</div>
	);
};

export default UserActions;
