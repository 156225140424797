/* eslint-disable react/jsx-no-bind */
import React from "react";

// ACTIONS
import { openAcknowledgeModal } from "../../../../../store/modules/headerNav/headerNav.slice";

// ASSETS
import useStyles from "./HelpItem.styles";

// TYPINGS
import { SupportCommunication } from "../../../../../store/modules/headerNav/headerNav.typings";
import { useDispatch } from "react-redux";
import { Icon } from "@agilekit/ui";

interface HelpItemProps {
	communication: SupportCommunication;
	needAcknowledgment?: boolean;
	lastItem?: boolean;
	url?: string;
	title?: string;
}

const HelpItem: React.FC<HelpItemProps> = ({
	communication,
	needAcknowledgment,
	lastItem,
	url,
	title,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const acknowledgeEvent = () => {
		dispatch(openAcknowledgeModal(communication));
	};

	return (
		<div className={lastItem ? classes.containerLastItem : classes.container}>
			<div className={classes.main}>
				<div className={classes.mainItem}>
					{communication.acknowledgementRequired ? (
						<div
							className={classes.acknowledgementItem}
							onClick={acknowledgeEvent}
							role="button"
							tabIndex={0}
						>
							<div>
								<span>{title ?? communication.title}</span>
							</div>
							{needAcknowledgment && (
								<div className={classes.acknowledgementIconContainer}>
									<Icon size={14} source="exclamation" color="R400" />
								</div>
							)}
						</div>
					) : (
						<a
							href={url ?? communication.url}
							target="_blank"
							rel="noreferrer"
						>
							{title ?? communication.title}
						</a>
					)}
				</div>
			</div>
		</div>
	);
};

export default HelpItem;
