import { RootState } from "../../configureStore";

export const getEnvironment = (state: RootState) => state.enviroment;

export const getFacilitySettings = (state: RootState) =>
	state.enviroment.facilitySettings;

export const getWaiverSettings = (state: RootState) =>
	state.enviroment.facilitySettings.waiverSettings;

export const getPosSettings = (state: RootState) =>
	state.enviroment.facilitySettings.pointOfSale;

export const getFacilitySettingsLoading = (state: RootState) =>
	state.enviroment.isSettingsLoading;

export const getWaiversFeature = (state: RootState) =>
	state.enviroment.facilitySettings.featuresOn.waiversOn;

export const getMembershipsFeature = (state: RootState) =>
	state.enviroment.facilitySettings.featuresOn.membershipsOn;

export const getFacilitySocialMedias = (state: RootState) =>
	state.enviroment.facilitySettings.socialMedias;

export const getHostedControlsUrl = (state: RootState) =>
	state.enviroment.paymentSettings.hostedControlsUrl;

export const getIsLoadingPaymentSettings = (state: RootState) =>
	state.enviroment.paymentSettings.isLoading;

export const getCookiesInfoState = (state: RootState) => {
	return state.enviroment.cookiesInfo;
};

export const getFacilityAliasState = (state: RootState) => state.enviroment.facilityAlias;
