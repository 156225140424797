import * as Counter from "./Counter";

import appInsightsReducer from "./modules/appInsights/appInsights.slice";
import authReducer from "./modules/auth/auth.slice";
import customersReducer from "./modules/customers/customers.slice";
import customerReducer from "./modules/customer/customer.slice";
import productCategoriesReducer from "./modules/productCategories/productCategories.slice";
import productReducer from "./modules/products/products.slice";
import waiverReducer from "./modules/waiver/waiver.slice";
import customerSearchReducer from "./modules/customerSearch/customerSearch.slice";
import enviromentReducer from "./modules/enviroment/enviroment.slice";
import activityLogReducer from "./modules/activityLog/activityLog.slice";
import pointOfSaleReducer from "./modules/pointOfSale/pointOfSale.slice";
import imagesResourcesReducer from "./modules/imagesResources/imageResources.slice";
import orderReducer from "./modules/order/order.slice";
import membershipsReducer from "./modules/memberships/memberships.slice";
import customerMembershipReducer from "./modules/customerMembership/customerMembership.slice";
import partyScheduleReducer from "./modules/partySchedule/partySchedule.slice";
import scheduleCustomerReducer from "./modules/scheduleCustomer/scheduleCustomer.slice";
import emailTemplateReducer from "./modules/emailTemplate/emailTemplate.slice";
import emailLogReducer from "./modules/emailLog/emailLog.slice";
import taxReducer from "./modules/taxes/taxes.slice";
import PaymentsReducer from "./modules/payments/payments.slice";
import HeaderNavReducer from "./modules/headerNav/headerNav.slice";
import UserReducer from "./modules/user/user.slice";
import customerWrapperReducer from "./modules/customerWrapper/customerWrapper.slice";
import agileTerminalReducer from "./modules/agileTerminal/agileTerminal.slice";

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
	appInsights: appInsightsReducer,
	auth: authReducer,
	counter: Counter.reducer,
	customers: customersReducer,
	customer: customerReducer,
	customerSearch: customerSearchReducer,
	customerWrapper: customerWrapperReducer,
	productCategories: productCategoriesReducer,
	products: productReducer,
	waiver: waiverReducer,
	pointOfSale: pointOfSaleReducer,
	enviroment: enviromentReducer,
	activityLog: activityLogReducer,
	imageResources: imagesResourcesReducer,
	order: orderReducer,
	customerMembership: customerMembershipReducer,
	partySchedule: partyScheduleReducer,
	scheduleCustomer: scheduleCustomerReducer,
	memberships: membershipsReducer,
	emailTemplate: emailTemplateReducer,
	emailLog: emailLogReducer,
	taxes: taxReducer,
	payments: PaymentsReducer,
	headerNav: HeaderNavReducer,
	user: UserReducer,
	agileTerminal: agileTerminalReducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface IAppThunkAction<TAction> {
	(dispatch: (action: TAction) => void, getState: () => any): void;
}
