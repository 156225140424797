import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		container: {
			borderBottom: `1px solid ${theme.palette.NEUTRAL020}`,
			padding: theme.spacing(2, 0),
		},
		containerLastItem: {
			padding: theme.spacing(2, 0),
		},
		main: {
			display: "flex",
			justifyContent: "space-between",
			color: theme.palette.NEUTRAL600,
			fontSize: theme.typography.pxToRem(14),
			"& strong": {
				color: theme.palette.NEUTRAL900,
				fontWeight: 500,
				borderBottom: "none",
			},
			"& a": {
				textDecoration: "none",
				color: theme.palette.NEUTRAL800,
				backgroundColor: "transparent",
				border: "none",
				borderRadius: 4,
				padding: theme.spacing(1.5, 2),
				cursor: "pointer",
				width: "100%",
				textAlign: "left",
				"&:hover": {
					backgroundColor: theme.palette.NEUTRAL020,
				},
			},
			"& > div": {
				display: "flex",
				alignItems: "center",
			},
		},
		mainItem: {
			display: "flex",
			width: "260px",
		},
		acknowledgementItem: {
			padding: theme.spacing(1.5, 2),
			cursor: "pointer",
			display: "flex",
			alignItems: "center",
			paddingRight: 4,
			justifyContent: "space-between",
			width: "100%",
			"&:hover": {
				backgroundColor: theme.palette.NEUTRAL020,
			},
		},
		acknowledgementIconContainer: {
			color: theme.palette.NEUTRAL000,
		},
	};
});

export default useStyles;
