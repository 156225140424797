import { makeStyles, ThemeAgile } from "@agilekit/ui";
import { isDesktop } from "../../../../utils";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		searchContainer: {
			minWidth: 220,
			height: 32,
			position: "relative",
			...(isDesktop()
				? {}
				: {
						[theme.breakpoints.down(1024)]: {
							minWidth: 500,
						},
						[theme.breakpoints.down("xs")]: {
							minWidth: 235,
						},
				  }),
		},
		search: {},
		searchOpen: {
			width: 670,
			position: "absolute",
			right: 0,
			top: 0,
			...(isDesktop()
				? {}
				: {
						[theme.breakpoints.down(1024)]: {
							maxWidth: 670,
							right: "-32%",
						},
						[theme.breakpoints.down("xs")]: {
							maxWidth: 390,
							right: "-42%",
						},
				  }),
			"& $searchInput": {
				backgroundColor: theme.palette.NEUTRAL000,
				boxShadow: theme.shadows[1],
				"& input": {
					color: theme.palette.NEUTRAL800,
					"&::placeholder": {
						color: theme.palette.NEUTRAL080,
					},
				},
			},
			"& $searchIcon": {
				color: theme.palette.NEUTRAL800,
			},
		},
		searchInput: {
			border: `1px solid ${theme.palette.NEUTRAL000}`,
			borderRadius: 4,
			paddingLeft: theme.spacing(2),
			"& input": {
				border: "none",
				backgroundColor: "transparent",
				color: theme.palette.NEUTRAL000,
				fontSize: theme.typography.pxToRem(14),
				paddingLeft: theme.spacing(0),
				"&:focus": {
					outline: "none",
				},
				"&::placeholder": {
					color: theme.palette.NEUTRAL000,
				},
			},
		},
		searchIcon: {
			fontSize: theme.typography.pxToRem(20),
			color: theme.palette.NEUTRAL000,
			marginRight: theme.spacing(3),
		},
		searchPanel: {},
	};
});

export default useStyles;
