import { createSlice } from "@reduxjs/toolkit";
import paymentSettingsReducer, {
	paymentSetttingsStates,
} from "./reducers/paymentSettings.reducer";
import socialMediasReducer from "./reducers/socialMedias.reducer";
import posSettingsReducer from "./reducers/posSettings.reducer";
import cookiesInfoReducer, {
	ICookiesInfo,
	cookiesInfoStates,
} from "./reducers/cookies.reducer";
import facilityAliasReducer, {
	IFacilityAliasState,
	facilityAliasStates,
} from "./reducers/facilityAlias.reducer";

interface ICustomerRequiredFields {
	customerRequiredFields: string[];
	organizationRequiredFields: string[];
	dependentRequiredFields: string[];
}

export interface IReferral {
	facilityId: number;
	referralId: number;
	description: string;
}

export interface IFacilitySettings {
	facilityId: number;
	facilityName: string;
	phone: string;
	facilityAddress: IAddress;
	homePageUrl: string;
	country: string;
	state: string;
	currencySymbol: string;
	currencyCode: string;
	dateDisplay: string;
	timezone: string;
	timezoneOffset: string;
	timezoneCode: string;
	customerMinimumAge: number;
	language: string;
	customerRequiredFields: ICustomerRequiredFields;
	forceCustomerEmailAsUnique: boolean;
	emergencyContactRequired: boolean;
	referrals: IReferral[];
	facilityManagementDomain: string;
	facilityCustomerFacingDomain: string;
	defaultAddressCountry: string;
	waiverSettings: IWaiverSettings;
	featuresOn: IFeaturesOn;
	environment: IEnvironment;
	pointOfSale: IPointOfSale;
	socialMedias: ISocialMedia[];
	facilityLogo: string;
	defaultDoNotSolicit: boolean;
	tipDisplayReplacement: string;
	pos2IsEnabled: boolean;
	merchantTrackEnabled: boolean;
}
export interface IEnvironment {
	isTestEnvironment: boolean;
}

export interface IFeaturesOn {
	waiversOn: boolean;
	membershipsOn: boolean;
	pointOfSaleOn: boolean;
	paymentLinkOn: boolean;
	wixMarketing: boolean;
}

export interface IPointOfSale {
	hasSetProfiles: boolean;
}

export interface IWaiverSettings {
	confirmCustomerWaivers: boolean;
	signWaiverMinimumAge: number;
	requireChildBirthdate: boolean;
	requirePhoneNumber: boolean;
	requireEmailAddress: boolean;
}

export interface IEnviroment {
	facilitySettings: IFacilitySettings;
	isSettingsLoading: boolean;
	isFirstLoadCompleted: boolean;
	isSocialMediaLoading: boolean;
	error: string;
	paymentSettings: IPaymentSettings;
	pointOfSale: IPointOfSale;
	cookiesInfo: ICookiesInfo;
	facilityAlias: IFacilityAliasState;
}

export interface IPaymentSettings {
	isLoading: boolean;
	hostedControlsUrl: string;
}

export type ISocialMedia = {
	socialMediaId: number;
	socialMediaType: SocialMediaType;
	linkUrl: string;
	isUpdate: boolean;
};

export enum SocialMediaType {
	Facebbok = 1,
	Instagram,
	Twitter,
}

export type IAddress = {
	addressId: number;
	address1: string;
	address2: string;
	address3: string;
	address4: string;
	city: string;
	state: string;
	zip: string;
	country: string;
};

let initialSettings: any = {};

try {
	const storage = localStorage.getItem("facilitySettings");
	if (storage) {
		initialSettings = JSON.parse(storage);
	}
} catch (e) {
	// localStorage not supported
}

const initialEnviroment: IEnviroment = {
	facilitySettings: {
		timezone: initialSettings.timezone || "Pacific Standard Time",
		timezoneOffset: initialSettings.timezoneOffset || "UTC-8",
		dateDisplay: initialSettings.dateDisplay || "MM-dd-yyyy",
		currencyCode: initialSettings.currencyCode || "$",
		currencySymbol: initialSettings.currencySymbol || "USD",
		customerMinimumAge: initialSettings.customerMinimumAge || 18,
		country: initialSettings.country || "United States",
		state: initialSettings.state || "",
		language: "en",
		customerRequiredFields: {
			customerRequiredFields: [] as string[],
			organizationRequiredFields: [] as string[],
		},
		forceCustomerEmailAsUnique: true,
		emergencyContactRequired: true,
		referrals: [] as IReferral[],
		facilityManagementDomain: "",
		facilityCustomerFacingDomain: "",
		waiverSettings: {
			confirmCustomerWaivers: false,
			signWaiverMinimumAge: initialSettings.customerMinimumAge || 18,
			requireChildBirthdate: false,
			requirePhoneNumber: true,
		} as IWaiverSettings,
		featuresOn: {
			waiversOn: false,
			membershipsOn: false,
		} as IFeaturesOn,
		socialMedias: [] as ISocialMedia[],
		pos2IsEnabled: true,
	} as IFacilitySettings,
	isSettingsLoading: false as boolean,
	isFirstLoadCompleted: false as boolean,
	isSocialMediaLoading: false,
	error: "" as string,
	...paymentSetttingsStates,
	pointOfSale: {
		hasSetProfiles: false,
	} as IPointOfSale,
	...cookiesInfoStates,
	...facilityAliasStates,
};
const enviromentSlice = createSlice({
	name: "ENVIRONMENT",
	initialState: initialEnviroment,
	reducers: {
		...socialMediasReducer(),
		...paymentSettingsReducer(),
		...posSettingsReducer(),
		...cookiesInfoReducer(),
		...facilityAliasReducer(),
		enviromentRequest: (state) => {
			return {
				...state,
				isSettingsLoading: true,
			};
		},
		enviromentSuccess: (state, { payload }) => {
			return {
				...state,
				isSettingsLoading: false,
				isFirstLoadCompleted: true,
				facilitySettings: {
					...state.facilitySettings,
					...payload.facilitySettings,
				},
				// paymentSettings: {
				// 	...state.paymentSettings
				// }
			};
		},
		enviromentFailure: (state, { payload }) => {
			return {
				...state,
				isSettingsLoading: false,
				error: payload,
			};
		},
	},
});

export const {
	enviromentRequest,
	enviromentSuccess,
	enviromentFailure,
	fetchSocialMediasFailure,
	fetchSocialMediasStart,
	fetchSocialMediasSuccess,
	fetchPaymentSettingsStart,
	fetchPaymentSettingsSuccess,
	fetchPaymentSettingsFailure,
	setEnvironmentHasPosProfiles,
	getCookiesInfo,
	getfacilityAlias,
	getfacilityAliasComplete,
	getfacilityAliasFail,
	openFacilityAliasModal,
	closeFacilityAliasModal,
} = enviromentSlice.actions;

export default enviromentSlice.reducer;
